/* Login.css */

body, html {
    margin: 0;
    padding: 0;
}

.containerRG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #282c34;
    width: 100vw;
}

.menubar {
    /* Navbar styles */
    background-color: #f8f9fa;
    padding: 10px;
    color: #000000;
    margin-bottom: 40px;
    margin-top: 30px;
    border-radius: 4px;
}

.bomenubarRG {
    margin: 0px 0;
    font-size: 24px;
    font-weight: bold;
    color: #1B509C;
    margin-bottom: 20px;
    margin-top: 0px;
    /*background-color: #fcb900;*/

    justify-content: center;
    align-items: center;
    display: flex;
    height: 4vw;
    width: 36%;
    /*border-radius: 3px;*/

    /*border-top: 3px solid #fcb900;
    border-bottom: 3px solid #fcb900;*/

    border-top: 3px solid #FDF2D0;
    border-bottom: 3px solid #FDF2D0;

    
}

.menubarRG {
    font-size: 24px;
    font-weight: bold;
    color: #282c34;
    /*margin-bottom: 60px;*/
    margin-top: 0px;
    background-color: #FDF2D0;

    justify-content: center;
    align-items: center;
    display: flex;
    
    /*height: 4vw;
    width: 35%;*/

    height: 80%;
    width: 100%;

    /*border: 10px solid;*/

    border-radius: 2px;
}

.welcome {
    /* Welcome section styles */
    margin: 20px 0;
    font-size: 24px;
    font-weight: bold;
    color: #f8f9fa;
    margin-bottom: 60px;
    margin-top: 0px;
}


.btn:disabled {
    background-color: #28a745; /* Lighter green color when disabled */
    cursor: not-allowed; /* Change cursor to indicate the button is not clickable */
    opacity: 0.7; /* Optional: Add some opacity to indicate the button is disabled */
}

.error {
    font-size: 13px;
    color: #c47a5b;
    font-weight: bold;
}

.login-formRG {
    /* Form container styles */
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 0.25rem;
    width: 30%;
    margin-bottom: 70px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.login-formRG h2 {
    /* Form heading styles */
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 0px;

    margin-right: 30px;

    
}

.login-formRG form {
    /* Form styles */
    display: flex;
    flex-direction: column;
    
}

.login-formRG .mb-3 {
    /* Form group styles */
    margin-bottom: 15px;
}

.login-formRG label {
    /* Label styles */
    font-weight: bold;
}

.login-formRG input {
    /* Input styles */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-formRG button {
    /* Button styles */
    background-color: #28a745;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-formRG button.btn-default {
    /* Default button styles */
    background-color: #f8f9fa;
    color: #000;
    border: 1px solid #ccc;
}

.login-formRG button.btn-default.border {
    /* Border button styles */
    border-color: #dee2e6;
}

.containerRG .createButton {
    background-color: #f8f9fa;
    color: #000;
    border: 1px solid #ccc;
    text-decoration: none;
    text-align: center;
    font-size: 13.8px;
    padding: 10px;

    font-weight: bold;
    
    border-radius: 4px;
    cursor: pointer;
}

.createButton {
    border-color: #dee2e6;
}


/* Add any additional styling as needed */

@media (max-width: 768px) { 
  
    .containerRG .login-formRG {
      width: 50%;
    }

    .containerRG .bomenubarRG {
        width: 60%;
        height: 10%;
    }

    
    
    
    }