.Landpage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    background: rgb(40,44,52);
background: linear-gradient(180deg, rgba(40,44,52,1) 0%, rgba(38,60,56,1) 58%, rgba(253,242,208,1) 100%);
    
    height: auto; /*check this when more added*/

    width: 100vw;
    color: #f8f9fa;
    
}

.Landpage .iframediv {
    position: relative;
    
    margin-top: 14px; /*check this when more added*/


    

    border-top: 10px solid #282c34;
    border-bottom: 24px solid #EEEEEE;
    border-left: 0px;
    border-right: 0px;
    
    
    width: 80%;
    /*border: 4px solid;*/
    clip-path: inset(0 0 27% 0) ;
}

.Landpage .iframediv iframe {
    height: 200px;
    
}

.Landpage .iframediv::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 28%;
    border-radius: 2px;
    background: #EEEEEE;
    /*clip-path: inset(0 0 75% 0);*/
    pointer-events: none; /* Allow interaction with elements behind the cover */
  }

  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    border-radius: 7px;
    border-bottom: 8px solid #EEEEEE;








    width: 80%;

    padding-top: 3px;
    padding-bottom: 3px;

    margin-top: 17px;
    /*margin-bottom: 14px;*/
    margin-bottom: 7px;
  }

  .menudiv {
    border: 0px solid;

    border-left: 0px solid;
    
    padding: 3px;

    font-weight: bold;

    margin-left: 3px;

    /*background-color: #dd841f;*/
  }

  .menudivBuy {
    

    
    
    padding: 3px;

    margin-left: 3px;


   
    border-bottom: 3px solid #bdc69f;
    border-left: 3px solid #bdc69f;
    background-color: #a5b373;

    font-weight: bold;
  }

  .menudivGR {
    

    border-left: 3px solid;
    
    padding: 3px;

    background-color: #DBA979;

    /*border-left: 3px solid #dd841f;
    border-bottom: 3px solid #dd841f;*/

    border-left: 3px solid #eec18e;;
    border-bottom: 3px solid #eec18e;

    font-weight: bold;


    margin-left: 3px;

    /*background-color: #dd841f;*/
  }

  .menudivAbout {
    

    border-left: 3px solid #eec18e;
    border-bottom: 3px solid #eec18e;
    
    padding: 3px;

    margin-left: 3px;

    background-color: #DBA979;
    /*background-color: #d88d47;*/
    
    font-weight: bold;
  }

  .menudivAboutInk {
    text-decoration: none;
    color: white;

  }

  .menudivBuyInk {
    text-decoration: none;
    color: white;

  }

  .pool {
    border-top: 7px solid #FDF2D0;
    border-bottom: 7px solid #FDF2D0;
    border-left: 7px solid #FDF2D0;
    border-right: 7px solid #FDF2D0;




    /*#a68b66*/
    color: #282c34;
    background-color: #FDF2D0;
    /*width: 30%;*/
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    border-radius: 3.5px;

    height: 10vh;
    padding: 0px;

    /*margin-bottom: 17px;*/
    margin-bottom: 35px;
  }

  .prize {
    font-size: 21.5px;

    /*border: 1px solid;*/

    font-family: monospace;
  }



.UserList {
    /*border: 2px solid;*/
    width: 100%;

    
}

.Landpage .winnertab {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    width: 100%;
  
    /*border-top: 3px solid;*/

    border-left: 0px;
    border-right: 0px;
    border-bottom: 7px solid;
  }
  
  .Landpage .winnerdiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;


    margin-left: 3%;
    margin-right: 4%;

    width: 40%;

    /*border: 1px solid;*/
  }

  .winame {
    color: #FDF2D0;
  }

  .repDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    
    /*border: 1px solid;*/

    color: red;
    font-weight: bold;

    margin-left: 5%;
    margin-right: 0%;

    height: 100%;
    width: 70%;

    transition: opacity 1s ease-in-out;
  }
  
  .otherdiv {
    height: 100%;

    margin-left: 3%;
    margin-right: 10%;

    width: 30%;

    /*border: 1px solid;*/
    
  }

  .otherdiv .h3 {
    color: red;
    
  }

  /*.repDiv {
    transition: opacity 1s ease-in-out; 
  }*/
  
  .repDiv.intermittent {
    opacity: 0.5; /* Set the opacity when the intermittent effect is active */
  }

  .Winner {
    border: 2px solid yellow;
  }

.UserNameBox.Winner {
    border: 6px solid yellow;
}

  .users {
    display: flex;
    /*border: 1px solid;*/

    border-left: 0px;
    border-right: 0px;

    border-radius: 10px;
    border-bottom: 10px solid;

    align-items: center;
    justify-content: center;
    width: 80%;
}

.Landpage .UserNameContainer {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
  justify-content: center;

  /*border-left: 0px;
  border-right: 0px;*/

  
  }
  
  .UserNameBox {
    border: 4px solid #ffffff;
    padding: 8px;
    margin: 8px;
    max-width: calc(100% / 6); /* Maximum of 6 boxes per row */
    box-sizing: border-box;
    min-width: 150px;

    font-weight: bold;

    border-radius: 1px;

    display: flex;
  align-items: center;
  justify-content: center;
  }

  .forGame {
    margin-top: 6%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

      width: 80%;
  }

  .Landpage .bote {
    font-weight: bold;
    font-size: 20px;
  }

.Landpage .historialbutton {
    /*margin-right: 40%;*/
    /*border: 1px solid;*/
    display: flex;
    
  }

  .Landpage .ExtraButton {
    background-color: #f8f9fa;
    color: white;
    border: 0px;
    border-bottom: 7px solid #DBA979;
    border-left: 3.5px solid #DBA979;
    background-color: #eec18e;

    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;
    text-align: center;
    font-size: 20px;
    
    /*padding-left: 11px;
    padding-right: 11px;*/

    padding-left: 7px;
    padding-right: 7px;

    margin: 2px;

    width: 100%;

    
    

    font-weight: bold;

    border-radius: 4px;
    cursor: pointer;
}

.Landpage .aButton {
  background-color: #f8f9fa;
  color: white;
  border: 0px;
  /*border-bottom: 7px solid #DBA979;
  border-left: 5px solid #DBA979;*/
  border-top: 7px solid #DBA979;
  border-bottom: 7px solid #DBA979;
  background-color: #eec18e;

  display: flex;
  align-items: center;

  text-decoration: none;
  text-align: center;
  font-size: 15px;
  
  padding-left: 11px;
  padding-right: 11px;

  
  margin: 2px;
  height: 5vh;
  min-height: auto;
  
  

  /*font-weight: bold;*/

  border-radius: 3px;
  /*cursor: pointer;*/
}

.Landpage .webtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-bottom: 0px;

  /*border: 1px solid;*/

  height: 100%;
    width: 70%;
}

.Landpage .logodiv {
  /*background-color: white;*/
  padding: 3px 0px;
  
  height: 29.5px; /* Adjust this value as needed */
  width: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: 2px;
    padding-right: 1px;

  border-radius: 3.8px;

  
  
}

.Landpage .logolott {

  height: 100%;
  width: auto;
}

.Landpage .title {
  color: white;
  font-size: 24px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin-left: 3px;

  font-weight: bold;
}

@media (max-width: 768px) { 

  .Landpage .pool {
    width: 92%;
    border-radius: 0px;
  }

  .Landpage {
    height: auto;
  }

  .Landpage .prize {
    font-size: 17px;
  }

  .Landpage .iframediv {
    width: 95%;

    
    border-top: 4px solid #282c34;
    border-bottom: 20px solid;

    
  }

  .Landpage .webtitle {
    display: none;
  }

  .Landpage .users{
    width: 100%;

    border: 0px;
  }

  .Landpage .winnerdiv {
    width: 70%;
    
  }

  .Landpage .winnertab {
    border-top: 0px;
  }

  .Landpage .UserNameContainer {
    border-bottom: 7px solid;
  }

  .menu {
    width: 100%;
  }

}
