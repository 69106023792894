.containerGame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #282c34;
    width: 100vw;
}


    

.containerGame .betdivSuccess {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    
    
    margin-top: 10px;
    margin-bottom: 0px;

    padding: 0px;

    height: 40vw;
    width: 70vw;

    /*border: 1px solid rgb(187, 137, 36);*/
}

.containerGame .legendHistoryGame {
    /*margin: 20px 0;*/
    font-size: 24px;
    font-weight: bold;
    color: #282c34;
    /*margin-bottom: 80px;*/
    margin-top: 0px;

    margin-left: 4px;

    

    gap: 10%;

    background-color: white;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 50%;

    border-radius: 3px;

    padding: 10px;

    /*border: 3px solid;
    border-color: #362416;

    border-radius: 1px;*/
}

.containerGame .glegendHistoryGame {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    

    font-size: 24px;
    font-weight: bold;
    color: #282c34;
    /*margin-bottom: 80px;*/
    /*margin-top: 0px;
    
    
    margin-left: 4px;*/
    /*margin-bottom: 80px;*/

    gap: 0px;

    font-family: monospace;

    background-color: white;
    height: 10%;
    width: 100%;

    margin-top: 10px;
    /*margin-bottom: 77px;*/
    
    
    border-bottom: 3px solid;
    border-color: #362416;
    border-radius: 1px;
}

.containerGame .topdivreloginCheckoutSuccess {
background-color: #FDF2D0;
color: #282c34;
}

.containerGame .divlegendHistoryGame {
    /*margin: 20px 0;*/
    font-size: 20px;
    font-weight: bold;
    color: #282c34;
    /*margin-bottom: 80px;*/
    /*margin-top: 0px;

    margin-left: 4px;*/

    background-color: white;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;

    margin: 0px 7px;

    /*border: 3px solid;
    border-color: #362416;
    border-radius: 1px;*/

}

.containerGame .botopdivreloginGame {
    margin: 20px 0;
    font-size: 24px;
    font-weight: bold;
    color: #dee7f3;
    margin-bottom: 10px;
    margin-top: 0px;
    

    justify-content: center;
    align-items: center;
    display: flex;
    height: 4vw;
    width: 38%;
    border-radius: 0px;

    border-top: 3px solid #FDF2D0;
    border-bottom: 3px solid #FDF2D0;
}

.containerGame .historyListGame {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    font-size: 24px;
    font-weight: bold;
    color: #282c34;
    /*margin-bottom: 80px;*/
    margin-top: 0px;
    

    /*margin-left: 4px;*/

    background-color: white;
    height: 13%;
    width: 100%;
    
}

.containerGame .historyItemGame {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    font-size: 17px;
    font-weight: bold;
    color: #282c34;
    /*margin-bottom: 80px;*/
    /*margin-top: 0px;

    margin-left: 4px;*/

    background-color: white;
    height: 100%;
    width: 100%;

    
    /*border-top: 2.4px solid;*/
    border-bottom: 3px solid;
    border-color: #362416;

    border-radius: 1px;
}

.containerGame .historyAmountGame {
    /*margin: 20px 0;*/
    font-size: 17px;
    font-weight: bold;
    color: #282c34;
    /*margin-bottom: 80px;*/
    /*margin-top: 0px;
    
    margin-left: 4px;*/
    
    background-color: white;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    
    margin: 0px 7px;
    
    /*border: 3px solid;*/
    /*border-color: #362416;
    border-radius: 1px;*/
    }
    
    .containerGame .historyDateGame {
    /*margin: 20px 0;*/
    font-size: 17px;
    font-weight: bold;
    color: #282c34;
    /*margin-bottom: 80px;*/
    /*margin-top: 0px;
    
    margin-left: 4px;*/
    
    background-color: white;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    
    margin: 0px 7px;
    
    /*border: 3px solid;*/
    /*border-color: #362416;
    border-radius: 1px;*/
    }
    
    .containerGame .historyResultGame {
    /*margin: 20px 0;*/
    font-size: 17px;
    font-weight: bold;
    color: #282c34;
    /*margin-bottom: 80px;*/
    /*margin-top: 0px;
    
    margin-left: 4px;*/
    
    background-color: white;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    
    margin: 0px 7px;
    
    /*border: 3px solid;*/
    /*border-color: #362416;
    border-radius: 1px;*/
    }

    .buyButtonGame {

        display: flex;
        justify-content: center;
        align-items: center;

        border: 0px solid;

        height: 30px;
        width: 90%;
        
    }

    .containerGame .buySuccessLinkGame {

        background-color: #28a745;
    color: white;
    border-bottom: 3px solid #1f6d31;
    border-left: 3px solid #1f6d31;
    text-decoration: none;
    
    text-align: center;

    font-weight: bold;

    
    font-size: 15px;
    
    padding: 10px;

    
    margin-left: 0px;

    width: 100%;

    border-radius: 4px;
    cursor: pointer;
    }

    .historyTableGame {
        width: 100%; /* Ensures the table uses available width */
        border-spacing: 15px 10px; /* Adds spacing between columns (15px) and rows (10px) */
        border-collapse: separate; /* Ensures border-spacing works */
    }
    
    .glegendHistoryGame th,
    .historyItemGame td {
        /* Adds padding inside table cells */
        text-align: left; /* Aligns text to the left */
    }
    
   
    
   

@media (max-width: 768px) { 

    .containerGame .botopdivreloginGame {
        margin-bottom: 100px;
    
        height: 4vh;
        width: 70%;

    }

    .containerGame .legendHistoryGame {
    
    gap: 20px;
        width: 100%;
        min-height: 52vh;
        min-width: 45vh;
    }
    
    .containerGame .buySuccessGame {
        width: 50%;
    }

    .containerGame .loggedGame {
        background-color: #c47a5b;
        font-size: 15px;
        /*text-align: center;*/
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      
      
        border-radius: 7px;
      
        height: 30%;
        width: 48%;
    }


}