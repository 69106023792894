body, html {
    margin: 0;
    padding: 0;
}

.Aboutcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #282c34;
    width: 100vw;
}

.webtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-bottom: 24px;
}

.Aboutcontainer .logodiv {
    background-color: white;
    padding: 3px 0px;
    
    height: 40px; /* Adjust this value as needed */
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: 3px;
    padding-right: 2px;

    border-radius: 3.8px;
    
}

.Aboutcontainer .logolott {

    height: 100%;
    width: auto;
}

.Aboutcontainer .title {
    color: white;
    font-size: 27px;
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    /*font-family: Courier, monospace;
    font-weight: bold;*/
    margin-left: 3px;
}

.legendMain {
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color: #FDF2D0;
    padding-left: 40px;
    padding-right: 40px;


    border-radius: 7px;
    /*border-top: 2.4px solid #f5dd96;*/
    border-left: 7px solid #d4cba8;
    border-bottom: 7px solid #d4cba8;

    width: 48%;
}


.headMain {
    color: #282c34;

    margin-bottom: 14px;

    font-weight: bold;
    font-size: 30px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.testMain {
    color: #282c34;

   

    font-weight: bold;
    font-size: 17px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.testMainOne {
    border-radius: 3px;
    border-left: 8px solid #282c34;
    padding-left: 8px;

    margin-left: 20px;
    margin-right: 20px;
}

.ruleMain {
    color: #282c34;

    

    font-weight: bold;
    font-size: 14px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.Aboutcontainer .welcome {
    margin-top: 24px;

    color: white;

    border-radius: 3.8px;

    border-bottom: 5px solid #bdc69f;
    border-left: 5px solid #bdc69f;
    background-color: #a5b373;
}

.Aboutcontainer .welcomeInk {
    color: white;
}