/* Login.css */

body, html {
    margin: 0;
    padding: 0;
}

.containerTick {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #282c34;
    width: 100vw;
}

.containerTick .wrap {
    display: flex;

    padding: 3px;

    max-width: 250px; /* Set a maximum width to force wrapping */
    
    border-radius: 1px;
    border: 4px solid #263c38;
    word-wrap: break-word; /* Allows breaking inside words */
    overflow-wrap: break-word; /* Ensures that long words are wrapped */
    white-space: normal;
}

.containerTick .checkbotopdivreloginCheck {
    margin: 20px 0;
    font-size: 24px;
    font-weight: bold;
    color: #1B509C;
    margin-bottom: 20px;
    margin-top: 0px;
    /*background-color: #fcb900;*/

    justify-content: center;
    align-items: center;
    display: flex;
    height: 4vw;
    width: 36%;
    /*border-radius: 3px;*/

    border-top: 3px solid #FDF2D0;
    border-bottom: 3px solid #FDF2D0;
}

.containerTick .checktopdivreloginCheck {
    font-size: 24px;
    font-weight: bold;
    color: #282c34;
    margin-bottom: 0px;
    margin-top: 0px;
    background-color: #FDF2D0;

    justify-content: center;
    align-items: center;
    display: flex;
    
    /*height: 4vw;
    width: 35%;*/

    height: 80%;
    width: 100%;

    border-radius: 2px;
}

.containerTick .formTitleCheckout {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 10px;

    font-family: monospace;

    font-size: 17px;

    padding-top: 3px;
    padding-bottom: 3px;

    font-weight: bold;

    border-top: 3px solid #28a745;
    border-bottom: 3px solid #28a745;
}

.containerTick .ticket {
    background-color: #28a745;

    font-size: 1px;
    color: #28a745;

    height: 24px;
    width: 24px;

    margin-right: 3px;
}

.containerTick .tickettest {
    margin-left: 7px;
}

.containerTick .login-formCheckout {
    /* Form container styles */
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 0.25rem;
    
    height: 58vh;
    width: 30%;
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.login-formCheckout h2 {
    /* Form heading styles */
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 0px;
}

.login-formCheckout form {
    /* Form styles */
    display: flex;
    flex-direction: column;
    
}

.containerTick .login-formCheckout .mb-3 {
    /* Form group styles */
    margin-bottom: 30px;

    word-wrap: break-word; /* Allows breaking inside words */
    overflow-wrap: break-word; /* Ensures that long words are wrapped */
    white-space: normal;

    max-width: 100%; /* Set a maximum width to force wrapping */
    display: block;
}

.containerTick .login-formCheckout label {
    /* Label styles */
    font-weight: bold;

    word-wrap: break-word; /* Allows breaking inside words */
    overflow-wrap: break-word; /* Ensures that long words are wrapped */
    white-space: normal;

    max-width: 100%; /* Set a maximum width to force wrapping */
    display: block;
}

.login-formCheckout input {
    /* Input styles */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-formCheckout button {
    /* Button styles */
    background-color: #28a745;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    font-weight: bold;

    border-bottom: 3px solid #1f6d31;
    border-left: 1px solid #1f6d31;
}

.login-formCheckout button.btn-default {
    /* Default button styles */
    background-color: #f8f9fa;
    color: #000;
    border: 1px solid #ccc;
}

.login-formCheckout button.btn-default.border {
    /* Border button styles */
    border-color: #dee2e6;
}

.containerTick .btn:disabled {
    background-color: #28a745; /* Lighter green color when disabled */
    cursor: not-allowed; /* Change cursor to indicate the button is not clickable */
    opacity: 0.7; /* Optional: Add some opacity to indicate the button is disabled */
}
/*mongodb+srv://vercel-admin-user-65b9393e45c46e1896d71d47:8WNqGQ22lK66FBcF@cluster0.4gverxk.mongodb.net/myFirstDatabase?retryWrites=true&w=majority*/

.containerTick .error {
    font-size: 13px;
    color: #c47a5b;
    font-weight: bold;
}

.containerTick .createButton {
    background-color: #f8f9fa;
    color: #000;
    border: 1px solid #ccc;
    text-decoration: none;
    text-align: center;
    font-size: 13.5px;
    padding: 10px;

    border-radius: 4px;
    cursor: pointer;
}

.containerTick .createButton {
    border-color: #dee2e6;
}

.containerTick .ExtraButton {
    background-color: #f8f9fa;
    color: #282c34;
    border: 0px;
    border-bottom: 7px solid #eec18e;
    border-left: 7px solid #eec18e;
    background-color: #DBA979;

    

    text-decoration: none;
    text-align: center;
    font-size: 20px;
    
    padding: 11px;

    margin: 2px;

    

    font-weight: bold;

    border-radius: 4px;
    cursor: pointer;
}


/* Add any additional styling as needed */

/*"headers": [
    {
      "source": "/(.*)",  
      "headers": [
        { "key": "Access-Control-Allow-Credentials", "value": "true" },
        { "key": "Access-Control-Allow-Origin", "value": "*" },
        { "key": "Access-Control-Allow-Origin", "value": "https://dice-frontend-xi.vercel.app" },
        { "key": "Access-Control-Allow-Methods", "value": "GET,OPTIONS,PATCH,DELETE,POST,PUT" },
        { "key": "Access-Control-Allow-Headers", "value": "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version" }
      ]
    }
  ]*/

  /* 
  mongodb+srv://vercel-admin-user-65b9393e45c46e1896d71d47:8WNqGQ22lK66FBcF@cluster0.4gverxk.mongodb.net/myFirstDatabase?retryWrites=true&w=majority
  */

  /*etc hosts*/
  /*nginx config*/
  /*aptache1*/

  /*sudo /usr/local/nginx/sbin/nginx -s reload*/

  @media (max-width: 768px) {
  
    .containerTick .checkbotopdivreloginCheck {
        margin: 20px 0;
        font-size: 24px;
        font-weight: bold;
        color: #1B509C;
        margin-bottom: 20px;
        margin-top: 0px;
        /*background-color: #fcb900;*/
    
        justify-content: center;
        align-items: center;
        display: flex;
        height: 7vw;
        width: 80%;
        /*border-radius: 3px;*/

        padding: 1px 0px;
    
        border-top: 3.8px solid #FDF2D0;
        border-bottom: 3.8px solid #FDF2D0;
    }

    .containerTick .topdivreloginCheckout {
        height: 100%;

       
    }

    .containerTick .legendHistoryGame {
    
    
        width: 100%;
        min-height: 52vh;
        min-width: 45vh;
    }
    
    .containerTick .buySuccessGame {
        width: 50%;
    }

    .containerTick .login-formCheckout {
        width: 70%;

        height: 40%;
    }


}
