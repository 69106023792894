.Bord {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    min-height: 100vh;
    background: rgb(40,44,52);
background: linear-gradient(180deg, rgba(40,44,52,1) 0%, rgba(38,60,56,1) 58%, rgba(253,242,208,1) 100%);
    width: 100vw;
    color: #f8f9fa;
    
    
}

.Bord .LoginMenu {
    width: 80%;
    border: 0px solid;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.forCount {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    
}

.bordMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  /*border-top: 1px solid;*/
  border-radius: 7px;
    border-bottom: 8px solid #EEEEEE;







  width: 100%;

  padding-top: 3px;
  padding-bottom: 3px;

  margin-top: 17px;
  /*margin-bottom: 14px;*/
  margin-bottom: 7px;
}

.ExtraButton {
    background-color: #f8f9fa;
    color: #000;
    border: 1px solid #ccc;
    text-decoration: none;
    text-align: center;
    font-size: 13.5px;
    padding: 6px;

    margin: 2px;

    font-weight: bold;

    border-radius: 4px;
    cursor: pointer;
}

.Bord .menudivBord {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid;

  font-size: 20px;
  border-left: 0px solid;
  
  padding: 3px;

  font-weight: bold;

  margin-left: 3px;
}

.Bord .menudivBuyBord {
  padding: 7px;

    margin-left: 3px;

    font-size: 15px;
   
    border-bottom: 3px solid #bdc69f;
    border-left: 3px solid #bdc69f;
    background-color: #a5b373;

    font-weight: bold;
}

.Bord .menudivLogBord {
  padding: 7px;

  margin-left: 3px;

  border-top: 0px;
  border-right: 0px;
 
  border-left: 4px solid #eec18e;
    border-bottom: 4px solid #eec18e;
    background-color: #DBA979;

    font-size: 15px;
  font-weight: bold;

  color: white;
}

.menudivBuyInkBord {
  text-decoration: none;
  color: white;
}

.Bord .winnertab {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  /*border: 1px solid;*/

  width: 100%;
  
    border-top: 0px solid;

    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px solid;
}

.Bord .winnerdiv {
  display: flex;
    align-items: center;
    justify-content: flex-end;


    margin-left: 3%;
    margin-right: 2%;

    width: 49%;
}

.otherdiv {
  height: 100%;

    margin-right: 10%;

    width: 30%;
}

.Winner {
    border: 2px solid yellow;
  }

.UserNameBox.Winner {
    border: 6px solid yellow;
  }

/*.Bord .iframediv {
    position: relative;
    width: 100%;
    border: 4px solid;
    clip-path: inset(0 0 27% 0);

    
    margin-top: 20px;

    border-top: 10px solid #282c34;
    border-bottom: 24px solid #EEEEEE;
    border-left: 0px;
    border-right: 0px;
    
}

.Bord .iframediv iframe {
  height: 200px;

  
 
}

.Bord .iframediv::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 28%;
    border-radius: 2px;
    background: #EEEEEE;
    //clip-path: inset(0 0 75% 0);//
    pointer-events: none; // Allow interaction with elements behind the cover //
 
  }*/

  .Bord .iframediv {
    position: relative;
    
    margin-top: 14px; /*check this when more added*/


    

    border-top: 10px solid #282c34;
    border-bottom: 24px solid #EEEEEE;
    border-left: 0px;
    border-right: 0px;
    
    
    width: 100%;
    /*border: 4px solid;*/
    clip-path: inset(0 0 27% 0) ;
}

.Bord .iframediv iframe {
    height: 200px;
    
}

.Bord .iframediv::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 28%;
    border-radius: 2px;
    background: #EEEEEE;
    /*clip-path: inset(0 0 75% 0);*/
    pointer-events: none; /* Allow interaction with elements behind the cover */
  }

.Bord .users {
    display: flex;
    /*border: 1px solid;*/

    align-items: center;
    justify-content: center;
    width: 80%;

    border: 0px solid;
}

.Bord .UserNameContainer {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
  justify-content: center;

    border-top: 7px solid;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 10px solid;
  
  }
  
  .UserNameBox {
    border: 4px solid #ffffff;
    padding: 8px;
    margin: 8px;
    max-width: calc(100% / 6); /* Maximum of 6 boxes per row */
    box-sizing: border-box;
    min-width: 150px;

      border-radius: 1px;

    font-weight: bold;

    display: flex;
  align-items: center;
  justify-content: center;
  }

  .ExtraButtonInk {
    text-decoration: none;
    color: #000;
  }

  .logged {
    background-color: #c47a5b;
    font-size: 15px;
    /*text-align: center;*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
  
    border-radius: 7px;
  
    height: 30%;
    width: 30%;
  }
  
  
  .loginButtontest {
    margin-top: 30px;
  
    text-align: center;
  }
  
  .loginButton {
    background-color: #28a745;
      color: white;
      border-bottom: 3px solid #1f6d31;
      border-left: 3px solid #1f6d31;
      text-decoration: none;
      text-align: center;
  
      font-weight: bold;
  
      
      font-size: 15px;
      
      padding: 10px;
  
      margin-top: 30px;
      margin-bottom: 17px;
  
      width: 30%;
  
      border-radius: 4px;
      cursor: pointer;
  
  
  }

  .Bord .pool {
    border-top: 7px solid #FDF2D0;
    border-bottom: 7px solid #FDF2D0;
    border-left: 7px solid #FDF2D0;
    border-right: 7px solid #FDF2D0;




    /*#a68b66*/
    color: #282c34;
    background-color: #FDF2D0;
    /*width: 30%;*/
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    border-radius: 3.5px;

    height: 10vh;
    padding: 0px;

    /*margin-bottom: 17px;*/
    margin-bottom: 35px;
  }

  .Bord .webtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
    margin-bottom: 0px;
  
    /*border: 1px solid;*/
  
    height: 100%;
      width: auto;
      margin-right: 3%;
  }
  
  .Bord .logodiv {
    /*background-color: white;*/
    padding: 3px 0px;
    
    height: 29.5px; /* Adjust this value as needed */
    width: 20%;
    
    display: flex;
    justify-content: center;
    align-items: center;
  
    padding-left: 3px;
      padding-right: 2px;
  
    border-radius: 3.8px;
  
    
    
  }
  
  .Bord .logolott {
  
    height: 100%;
    width: auto;
  }
  
  .Bord .title {
    color: white;
    font-size: 24px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-left: 3px;
  
    font-weight: bold;
  }

  @media (max-width: 768px) { 
  
  .Bord .logged {
    width: 50%;
  }

  .Bord .pool {
    /*width: 90%;*/
    width: 120%;
    border-radius: 3.5px;
  }

  .Bord .prize {
    /*width: 90%;*/
    font-size: 20px;
    
  }

  .Bord .iframediv  {
    width: 120%;
  }

  .Bord .webtitle {
    display: none;
  }

  .Bord .users{
    width: 100%;

    border: 0px;
  }

  .Bord .winnerdiv {
    width: 70%;
    margin-right: 3%;
    
  }

  .Bord .winnertab {
    border-top: 0px;
  }

  .Bord .UserNameContainer {
    border-bottom: 7px solid;
  }

  .Bord .bordMenu {
    /*border-top: 1px solid;*/
    width: 140%;
  }
  
  
  }
