/* Login.css */

body, html {
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #282c34;
    width: 100vw;
}

.botopdivreloginCheckout {
    margin: 20px 0;
    font-size: 24px;
    font-weight: bold;
    color: #1B509C;
    margin-bottom: 50px;
    margin-top: 0px;
    /*background-color: #fcb900;*/

    justify-content: center;
    align-items: center;
    display: flex;
    height: 4vw;
    width: 35%;
    /*border-radius: 3px;*/

    border-top: 3px solid #fcb900;
    border-bottom: 3px solid #fcb900;
}

.topdivreloginCheckout {
    font-size: 24px;
    font-weight: bold;
    color: #1B509C;
    /*margin-bottom: 60px;*/
    margin-top: 0px;
    background-color: #ffffff;

    justify-content: center;
    align-items: center;
    display: flex;
    
    /*height: 4vw;
    width: 35%;*/

    height: 80%;
    width: 100%;

    border-radius: 3px;
}

.formTitleCheckout {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 10px;

    font-family: monospace;

    font-size: 17px;

    padding-top: 3px;
    padding-bottom: 3px;

    font-weight: bold;

    border-top: 3px solid #28a745;
    border-bottom: 3px solid #28a745;
}

.ticket {
    background-color: #28a745;

    font-size: 1px;
    color: #28a745;

    height: 24px;
    width: 24px;

    margin-right: 3px;
}

.tickettest {
    margin-left: 7px;
}

.login-formCheckout {
    /* Form container styles */
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 0.25rem;
    
    height: 40vh;
    width: 30%;
    margin-bottom: 70px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.login-formCheckout h2 {
    /* Form heading styles */
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 0px;
}

.login-formCheckout form {
    /* Form styles */
    display: flex;
    flex-direction: column;
    
}

.login-formCheckout .mb-3 {
    /* Form group styles */
    margin-bottom: 45px;
}

.login-formCheckout label {
    /* Label styles */
    font-weight: bold;
}

.login-formCheckout input {
    /* Input styles */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-formCheckout button {
    /* Button styles */
    background-color: #28a745;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    font-weight: bold;

    border-bottom: 3px solid #1f6d31;
    border-left: 1px solid #1f6d31;
}

.login-formCheckout button.btn-default {
    /* Default button styles */
    background-color: #f8f9fa;
    color: #000;
    border: 1px solid #ccc;
}

.login-formCheckout button.btn-default.border {
    /* Border button styles */
    border-color: #dee2e6;
}

.createButton {
    background-color: #f8f9fa;
    color: #000;
    border: 1px solid #ccc;
    text-decoration: none;
    text-align: center;
    font-size: 13.5px;
    padding: 10px;

    border-radius: 4px;
    cursor: pointer;
}

.createButton {
    border-color: #dee2e6;
}


/* Add any additional styling as needed */

/*"headers": [
    {
      "source": "/(.*)",  
      "headers": [
        { "key": "Access-Control-Allow-Credentials", "value": "true" },
        { "key": "Access-Control-Allow-Origin", "value": "*" },
        { "key": "Access-Control-Allow-Origin", "value": "https://dice-frontend-xi.vercel.app" },
        { "key": "Access-Control-Allow-Methods", "value": "GET,OPTIONS,PATCH,DELETE,POST,PUT" },
        { "key": "Access-Control-Allow-Headers", "value": "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version" }
      ]
    }
  ]*/

  /* 
  mongodb+srv://vercel-admin-user-65b9393e45c46e1896d71d47:8WNqGQ22lK66FBcF@cluster0.4gverxk.mongodb.net/myFirstDatabase?retryWrites=true&w=majority
  */

  /*etc hosts*/
  /*nginx config*/
  /*aptache1*/

  /*sudo /usr/local/nginx/sbin/nginx -s reload*/